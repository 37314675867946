<template>
  <base-view title="Reporte de Saldos por Partida">
    <div class="container-fluid mt-4">
      <div class="card">
        <div class="card-body">
          <reporte-saldos-partidas/>
        </div>
      </div>
    </div>
  </base-view>
</template>

<script>
import ReporteSaldosPartidas from '@/components/Reportes/ReporteSaldosPartidas'

export default {
  name: 'ReporteSaldosPartidasView',

  components: {
    ReporteSaldosPartidas
  }
}
</script>
